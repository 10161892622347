import {
  FormEvent,
  FC,
  useEffect,
  useState,
  ChangeEventHandler,
  useCallback,
} from 'react';

import { IoMdHelp } from 'react-icons/io';

import * as Yup from 'yup';
import { useToast } from '@chakra-ui/react';

import {
  getValidationErrors,
  ValidationErrors,
} from '~/shared/utils/getValidationErrors';
import { useSettings } from '~/shared/hooks/settings';
import { useModal } from '~/shared/hooks/modal';
import { routes } from '~/shared/constants/routes';
import { Input } from '~/shared/components/Form/Input';
import { FormGroup } from '~/shared/components/Form/FormGroup';
import { Button } from '~/shared/components/Form/Button';

import { useAuth } from '../../hooks/auth';
import { RedirectToResaleModal } from '../../components/RedirectToResaleModal';
import { Link } from '../../components/Link';
import { Header } from '../../components/Header';

interface FormData {
  email: string;
  password: string;
}

const signInSchema = Yup.object().shape({
  email: Yup.string().email('e-mail inválido').required('e-mail é obrigatório'),
  password: Yup.string().required('senha é obrigatória'),
});

export const SignIn: FC = () => {
  const { settings } = useSettings();

  const { signIn, setPhoneNumber } = useAuth();

  const toast = useToast();
  const { openModal } = useModal();

  const [formData, setFormData] = useState<FormData>({
    email: '',
    password: '',
  });
  const [formErrors, setFormErrors] = useState({} as ValidationErrors);

  const [isSigning, setIsSigning] = useState(false);

  useEffect(() => {
    setPhoneNumber('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeInput: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { name, value } = event.target;

      if (formErrors[name]) {
        setFormErrors((prevState) => ({
          ...prevState,
          [name]: '',
        }));
      }

      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [formErrors]
  );

  async function handleSignIn(
    event: FormEvent<HTMLFormElement>
  ): Promise<void> {
    event.preventDefault();

    try {
      setIsSigning(true);

      const data = {
        ...formData,
        email: formData.email.trim(),
      };

      await signInSchema.validate(data, {
        abortEarly: false,
      });

      await signIn(data);
    } catch (error) {
      const tag = error?.response?.data?.tag;

      setIsSigning(false);

      if (tag === 'incorrect_resale_domain') {
        let redirectToDomain = error?.response?.data?.content
          ?.redirectToDomain as string;

        if (redirectToDomain) {
          if (redirectToDomain.includes('www')) {
            redirectToDomain = redirectToDomain.replace('www.', '');
          }

          openModal(RedirectToResaleModal, {
            modalProps: {
              closeOnClickOutside: false,
              closeOnEsc: false,
              shouldConfirm: false,
              showHeader: false,
            },
            props: {
              redirectToDomain,
            },
          });

          return;
        }
      }

      if (error instanceof Yup.ValidationError) {
        const validationErrors = getValidationErrors(error);

        setFormErrors((prevState) => ({
          ...prevState,
          ...validationErrors,
        }));

        return;
      }

      toast({
        status: 'error',
        isClosable: true,
        position: 'top-right',
        ...(tag === 'user_inactive'
          ? {
              title: error.response.data.message,
            }
          : {
              title: 'Ops, ocorreu um erro ao fazer o login!',
              description:
                'Parece que seu e-mail e/ou senha está(ão) incorreto(s).',
            }),
      });
    }
  }

  return (
    <>
      <form onSubmit={handleSignIn}>
        <Header title="seja bem vindo_" subtitle="faça seu login" />

        <FormGroup>
          <Input
            name="email"
            placeholder="E-mail"
            autoComplete="email"
            value={formData.email}
            error={formErrors.email}
            onChange={handleChangeInput}
            variant="default"
          />
        </FormGroup>

        <FormGroup>
          <Input
            name="password"
            autoComplete="current-password"
            type="password"
            placeholder="Senha"
            value={formData.password}
            error={formErrors.password}
            onChange={handleChangeInput}
            variant="default"
          />
        </FormGroup>

        <FormGroup>
          <Link href={routes.auth.forgotPassword} title="esqueci a senha" />
        </FormGroup>

        <FormGroup>
          <Button type="submit" isLoading={isSigning}>
            acessar
          </Button>
        </FormGroup>
      </form>

      <Link href={routes.auth.signUp} title="quero me cadastrar" />

      {settings?.helpLink && (
        <Link
          href={settings.helpLink}
          target="_blank"
          rel="noreferrer"
          title="ajuda"
          icon={IoMdHelp}
        />
      )}
    </>
  );
};
